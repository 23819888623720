import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import SEO from "../components/seo"


const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ThankingTemplate = (props) => {
  const PageData = props.data?.strapiPage
  const type= typeof window!=="undefined"?window.history.state?.type:""
  const currentUrl=typeof window!=="undefined"?window.history.state?.currentUrl:""
  const thankingAddress=typeof window!=="undefined"?window.history.state?.thankingAddress:""
  const title=typeof window!=="undefined"?window.history.state?.title:"";
  const thankyou_image=typeof window!=="undefined"?window.history.state?.thankyou_image?.url:""
  const thankyou_content=typeof window!=="undefined"?window.history.state?.thankyou_content:""


  const bannerImage=thankyou_image?thankyou_image:PageData?.banner?.image?.url


  return (
    <Layout  popularSearch={PageData?.select_popular_search?.title}>
      <div className="thankyou-wrapper">
        <div className="thankingpage-banner">
         
          <div className="thanking-banner-img" style={{backgroundImage:`url(${bannerImage})`}}></div>
         <div className="overlay-bg"></div>
         <div className="tq-banner-content">
            {/* <h1>Thank you for registering {type=="campaign"?"":title}</h1>
            <p className="tq-desc">
             <ContentModule Content={PageData?.banner?.banner_content?.data?.banner_content} />
            </p> */}

            {thankyou_content?.data?.thankyou_content?
             <ContentModule Content={thankyou_content?.data?.thankyou_content} />
             :
            <>
             <h1>Thank you for registering {type=="campaign"?"":title}</h1>
            <p>
             <ContentModule Content={PageData?.banner?.banner_content?.data?.banner_content} />
            </p> 
            </>
            }
            
            <Link to={currentUrl}>
              <button className="button button-filled-green">{type=="campaign"?"Back to page":"Back to event page"}</button>
            </Link>
         </div>
        </div>
        {thankingAddress&&
        <div className="display_address">
          <Container>
              <p className="address">
                {thankingAddress}
              </p>
          </Container>
        </div>
        }
        
      </div>
    </Layout>
  )
}

export default ThankingTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  const slug =pageData?.choose_menu?.length>0 ?  pageData?.choose_menu[0]?.slug : ""
 
  const isNoIndexPage = slug === "buy" || slug === "rent" || slug ==="new-developments"
  || slug === "sell" || slug === "thank-you"
  return(
    <SEO isNoIndexPage={isNoIndexPage}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
    }
  }
`
